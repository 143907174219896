// assets

// constant

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const pages = {
    id: 'pages',
    title: 'Dashboard',
    type: 'group',
    children: [
        {
            id: 'user',
            title: 'User',
            type: 'item',
            url: '/user'
        },
        {
            id: 'verification',
            title: 'Verification',
            type: 'item',
            url: '/verification'
        },
        {
            id: 'role',
            title: 'Role',
            type: 'item',
            url: '/role'
        }
        
    ]
};

export default pages;
