const config = {
    // baseUrl: 'http://13.201.146.169',
    baseUrl: 'https://server.callsheetsmanager.com',
    basename: '/',
    defaultPath: '/login',
    fontFamily: `'Roboto', sans-serif`,
    borderRadius: 12
};

export default config;
