import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

// utilities routing
const UtilsTypography = Loadable(lazy(() => import('views/utilities/Typography')));
const UtilsColor = Loadable(lazy(() => import('views/utilities/Color')));
const UtilsShadow = Loadable(lazy(() => import('views/utilities/Shadow')));
const UtilsMaterialIcons = Loadable(lazy(() => import('views/utilities/MaterialIcons')));
const UtilsTablerIcons = Loadable(lazy(() => import('views/utilities/TablerIcons')));
const ForgotPassword = Loadable(lazy(() => import('views/pages/authentication/auth-forms/Forgot')));
const ResetPassword = Loadable(lazy(() => import('views/pages/authentication/auth-forms/Resetpassword')));
const Logout = Loadable(lazy(() => import('views/pages/authentication/auth-forms/Logout')));

// sample page routing
const User = Loadable(lazy(() => import('views/user/User')));
const Verification = Loadable(lazy(() => import('views/verification/Verification')));
const Role = Loadable(lazy(() => import('views/role/Role')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    exact: true,
    element: <MainLayout />,
    children: [
        {
            path: '/',
            element: <Navigate to="/login" />
        },
        
        {
            path: '/utils/util-typography',
            element: <UtilsTypography />
        },
        {
            path: '/utils/util-color',
            element: <UtilsColor />
        },
        {
            path: '/utils/util-shadow',
            element: <UtilsShadow />
        },
        {
            path: '/icons/tabler-icons',
            element: <UtilsTablerIcons />
        },
        {
            path: '/icons/material-icons',
            element: <UtilsMaterialIcons />
        },
        {
            path: '/user',
            element: <User />
        },
        {
            path: '/verification',
            element: <Verification />
        },
        {
              path: '/forgot',
              element: <ForgotPassword />
        },
        {
            path: '/reset',
            element: <ResetPassword />
      },
        {
            path: '/role',
            element: <Role />
        },
        {
            path: '/logout',
            element: <Logout />
        }
    ]
};

export default MainRoutes;
